import { CLOTHES_CLASSITION } from '../components/headerSearch/constant';
import type { FindStallStore } from './store';

export const PAGE_SIZE = 10;

export const FILTER_ITEMS = (context: FindStallStore) => {
  return [
    {
      type: 'select',
      label: '',
      field: 'sidx',
      allowClear: true,
      placeholder: '综合排序',
      data: [
        {
          label: '综合排序',
          value: '',
        },
        {
          label: '7日上新降序',
          value: 'goodsPushTimeRank',
        },
        {
          label: '销量降序',
          value: 'saleNum',
        },
      ],
    },
    {
      type: 'select',
      label: '',
      allowClear: true,
      field: 'mainCategoryId',
      placeholder: '主营',
      data: CLOTHES_CLASSITION,
    },
    {
      type: 'select',
      label: '',
      allowClear: true,
      placeholder: '全部城市',
      field: 'cityId',
      onChangeCallback: (value) => {
        context.getDistrictOptions(value);
      },
    },
    {
      type: 'select',
      allowClear: true,
      placeholder: '全部市场',
      label: '',
      field: 'businessDistrictId',
    },
  ];
};

export const FindStallBgColor = {
  '0': { backgroundImage: 'linear-gradient(141deg, #FFF4E9 0%, #FFDEDE 100%)' },
  '1': { backgroundImage: 'linear-gradient(141deg, #FCFFE9 0%, #FFDEEB 100%)' },
  '2': { backgroundImage: 'linear-gradient(141deg, #E9FFF2 0%, #E2DEFF 100%)' },
  '3': { backgroundImage: 'linear-gradient(141deg, #EEE9FF 0%, #FFDEE1 100%)' },
  '4': { backgroundImage: 'linear-gradient(141deg, #E9FAFF 0%, #FFDEDE 100%)' },
};
