import type { BaseData, PaginationData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { observable, action, set } from 'mobx';
import { mapOptions, api } from '../../utils';
import { FilterStore } from '../components';
import PaginationStore from '../components/pagination/store';
import { FILTER_ITEMS, PAGE_SIZE } from './constant';

export class FindStallStore {
  constructor() {
    this.initData();
  }

  public paginationStore = new PaginationStore({ parent: this });

  @observable public pageInfo = {
    totalCount: 0,
    totalPageCount: 0,
  };

  public carouselRef: any;

  public cityDistrictDict = [];// 城市商圈字典对应

  @observable public hoverFlag = false;// 是否hover找档口

  @observable public findStallList = [];// 找档口列表

  @observable public loading = false;// 列表页loading

  @observable public vendorList = [];// 分页档口列表

  @action public toggleHover = (flag: boolean) => {
    this.hoverFlag = flag;
  };

  public mainNext = () => {
    this.carouselRef.next();
  };

  public mainPre = () => {
    this.carouselRef.prev();
  };

  public getVendorList = () => {
    const { current } = this.paginationStore;
    const params = this.filterParams.params;

    const data = {
      page: current,
      pageSize: PAGE_SIZE,
      sord: 'desc',
    };

    Object.assign(data, params);

    this.loading = true;

    request<PaginationData>({
      url: '/api/mall/rest/square/anon/shop/list',
      method: 'POST',
      data,
    }).then(action((res) => {
      const { totalCount, totalPageCount } = res.data;
      this.vendorList = res?.data?.list || [];

      this.pageInfo = {
        totalCount,
        totalPageCount,
      };

      // 展示活动价 总价 折扣价中最低的那个
      this.vendorList.forEach((eachVendor) => {
        Array.isArray(eachVendor.goodsList) && eachVendor.goodsList.length > 0 && eachVendor.goodsList.forEach((eachGood) => {
          const { activityPrice, discountPrice, wholePrice } = eachGood;
          const priceList = [
            activityPrice,
            discountPrice,
            wholePrice,
          ].filter((item) => typeof (item) === 'number' && !isNaN(item));
          eachGood.price = Math.min(...priceList);
        });
      });
    }))
      .finally(() => {
        this.loading = false;
      });
  };

  public onPageChange = (page: number, pageSize: number) => {
    this.getVendorList();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  public filterParams = new FilterStore({
    filterItems: FILTER_ITEMS(this),
    handleSearch: () => {
      this.resetPager();
      this.getVendorList();
    },
  });

  // 获取找档口数据
  public getFindStalls = () => {
    request<BaseData<[]>>({ url: '/api/mall/rest/square/anon/shop/site?type=2&goodsNum=5' }).then((res) => {
      this.findStallList = res.data.slice(0, 6) || [];
    });
  };

  // 获取数据
  public initData = () => {
    this.getFindStalls();
    this.getVendorList();
    this.initOptions();
  };

  // 初始化选项
  public initOptions = () => {
    // 获取城市
    request<BaseData<[]>>({ url: api.queryBusinessDistrict }).then((res) => {
      this.cityDistrictDict = res.data;
      const cityOptions = mapOptions(res.data, 'cityId', 'cityName');
      this.filterParams.updateFilterItems({ cityId: cityOptions });
    });
  };

  public getDistrictOptions = (cityId) => {
    // 获取商圈
    const selectedItem = this.cityDistrictDict.find((item) => item.cityId === cityId);
    const districtOptions = mapOptions(selectedItem.businessDistrictList, 'id', 'name');
    this.filterParams.updateFilterParams({ businessDistrictId: '' });
    this.filterParams.updateFilterItems({ businessDistrictId: districtOptions });
  };

  public setCarousel = (el) => {
    this.carouselRef = el;
  };

  // 重置分页
  private resetPager = () => {
    set(this.paginationStore, { current: 1 });
  };
}

