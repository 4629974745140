import { DoubleRightOutlined } from '@ant-design/icons';
import { Carousel, Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { Filter, NoLackTag, PaginationCus } from '../components';
import { SwiperButton } from '../homePage/dynamicInfo';
import { PAGE_SIZE, FindStallBgColor } from './constant';
import styles from './index.less';
import type { FindStallStore } from './store';

export const RecommendVendor: React.FC<{ store: FindStallStore; }> = (props) => {
  return (
    <div className={styles.recommendVendor}>
      <div className={styles.recommendVendorTitle}>
        推荐档口
      </div>
      <VendorCarousel store={props.store}/>
    </div>
  );
};

export const VendorList: React.FC<{ store: FindStallStore; }> = observer((props) => {
  const { filterParams, vendorList, paginationStore, pageInfo, loading } = props.store;
  return (
    <div className={styles.vendorList}>
      <Filter store={filterParams}/>
      <Spin spinning={loading}>
        {
          Array.isArray(vendorList) ? vendorList.map((item) => (
            <EachVendor
              item={item}
              key={item.id}
            />
          )) : null
        }
        {
          vendorList?.length > 0 ? (
            <PaginationCus
              pageSize={PAGE_SIZE}
              store={paginationStore}
              totalCount={pageInfo.totalCount}
              totalPageCount={pageInfo.totalPageCount}
            />
          ) : null
        }
      </Spin>
    </div>
  );
});

const VendorCarousel: React.FC<{ store: FindStallStore; }> = observer((props) => {
  const { findStallList, setCarousel, hoverFlag, toggleHover, mainPre, mainNext } = props.store;
  return (
    <div
      className={styles.carouselWrapper}
      onMouseEnter={() => {
        toggleHover(true);
      }}

      onMouseLeave={() => {
        toggleHover(false);
      }}
    >
      <Carousel
        autoplay
        dots={false}
        ref={setCarousel}
      >
        {
          Array.isArray(findStallList) ? findStallList.slice(0, 5)?.map((item, index) => (
            <EachCarouselItem
              index={index}
              item={item}
              key={item.shopId}
            />
          )) : null
        }
      </Carousel>
      <SwiperButton
        mainNext={mainNext}
        mainPre={mainPre}
        nextStyle={{
          top: 85,
          opacity: hoverFlag ? 1 : 0,
        }}
        preStyle={{
          top: 85,
          opacity: hoverFlag ? 1 : 0,
        }}
      />

    </div>
  );
});

// 找档口轮播
const EachCarouselItem: React.FC<{ item; index; }> = (props) => {
  const { item, index } = props;
  return (
    <div
      className={styles.carouselItem}
      key={item.shopId}
      onClick={() => {
        window.open(`/egenie-ts-vogue/stallDetail/index?vendorId=${item.shopId}`);
      }}
      style={FindStallBgColor[index]}
    >
      <div>
        <div className={styles.shopTitle}>
          {item.shopName}
        </div>
        <div className={styles.marketWrapper}>
          <div className={styles.marketLabel}>
            {item.marketLabelName}
          </div>
          {item.unionFlag ? <NoLackTag/> : null}
        </div>
        <div
          className={styles.shopButton}
        >
          进店查看
        </div>
      </div>
      <div className={styles.carouselItemRight}>
        {
          item?.picList?.length > 0 ? item.picList.map((item) => (
            <img
              className={styles.carouselmg}
              key={item.id}
              src={item.url}
            />

          )) : null
        }
      </div>
    </div>
  );
};

const EachVendor: React.FC<{ item; }> = (props) => {
  const { id, shopName, tagList, marketLabelName, goodsList, newPushGoodsNum, unionFlag } = props.item;
  const jumpToStall = () => {
    window.open(`/egenie-ts-vogue/stallDetail/index?vendorId=${id}`);
  };
  return (
    <div
      className={styles.eachVendor}
    >
      <div className={styles.vendorTitle}>
        <div
          className={styles.marketTitleWrapper}
          onClick={jumpToStall}
        >
          <span className={styles.marketTitle}>
            {shopName}
          </span>
          <span className={styles.marketLabel}>
            {marketLabelName}
          </span>
          {
            Array.isArray(tagList) ? tagList.map((tag) => (
              <span
                className={styles.tagLabel}
                key={tag.id}
              >
                {tag.tagName}
              </span>
            )) : null
          }
          {unionFlag ? <NoLackTag/> : null}
        </div>
        <div
          className={styles.onNew}
          onClick={jumpToStall}
        >
          {
            newPushGoodsNum ? `今日上新${newPushGoodsNum > 999 ? '999+' : newPushGoodsNum}款` : '进店'
          }
          <DoubleRightOutlined/>
        </div>
      </div>
      <div
        className={styles.vendorImgWrapper}
        onClick={jumpToStall}
      >
        {
          Array.isArray(goodsList) ? goodsList.map((good) => (
            <VendorImg
              key={good.goodsId}
              price={good.price}
              url={good.mainPicUrl}
            />
          )) : null
        }
      </div>
    </div>
  );
};

const VendorImg = (props) => {
  const { url, price } = props;
  return (
    <div>
      <div className={styles.vendorImgContainer}>
        <img
          className={styles.vendorImg}
          src={url}
        />
      </div>
      <div className={styles.priceWrapper}>
        <span className={styles.price}>
          ¥
          <span className={styles.font20}>
            {price}
          </span>
        </span>
      </div>
    </div>
  );
};
