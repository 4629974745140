import React from 'react';
import { Navigation, Footer, FooterLogo } from '../components';
import { RecommendVendor, VendorList } from './components';
import styles from './index.less';
import { FindStallStore } from './store';

const store = new FindStallStore();

const FindStall: React.FC = () => {
  return (
    <div className={styles.findStall}>
      <Navigation/>
      <RecommendVendor store={store}/>
      <VendorList store={store}/>
      <FooterLogo/>
      <Footer/>
    </div>
  );
};

export default FindStall;
